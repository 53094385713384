import React from 'react'

export const AlertComponent = ({
  setAlertShow,
  Alertcomp
}) => {
  return (
    <div className="modal"
      onClick={() => {
        setAlertShow(false)
      }}
    >
      <div>
        <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
          <div className="col-lg-4 col-1"></div>
          <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
            <div className="p-5">
              <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
              <div className="sign-in-button-1 col-8">
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}