import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

export default function AllBooksList() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()
  const LoginUser = useSelector((state) => state.LoginState);

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_book)
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }
  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {
      per_page: 1000, order: "asc",
      _fields: "id,title,slug,meta",
    })
    console.log(location.pathname.slice(11))
  }, [])

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Books</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_exam} />
              </div>
            ))}
            {AllExamList1.length == 0 &&
              <div style={{ padding: '10px' }}>
                No Books Available Right now
              </div>
            }
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

    </div>
  )
}

const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  const [Code, setCode] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(false)
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={"https://api.lpkkoreabmn.com/wp-content/uploads/2023/10/logobwm.png"}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      }
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>

      <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
        <div onClick={() => { setStartPressed(true) }} className="sign-in-button-1 col-8 ">
          <div style={{ color: "#fff" }}>
            Open Book
          </div>
        </div>
      </div >
      {
        StartPressed &&
        <div className="modal">
          <div className="row">
            <div className="col-1" />
            <div class="modal-dialog" role="document" className="col-10">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{item.title.rendered}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    onClick={() => { setStartPressed(false) }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div style={{ minHeight: "80vh", overflowY: "scroll" }}>
                    <iframe
                      style={{ width: "100%", height: "80vh" }}
                      src={item.meta.book_pdf} title="W3Schools Free Online Web Tutorials"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )

}