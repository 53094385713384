import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { ImageURL, MediaUpload } from '../Constant/api';

export default function FileUpload(props) {
  const {
    ImageUrl,
    setImageUrl,
    MediaUploadType,
    setMediaUploadType,
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)
  const [URL, setURL] = React.useState("")

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("2", file)
    // setMediaUploadType(file.type.split("/")[0])
    MediaUpload(file)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        console.log(data.source_url)
        setImageUrl(data.source_url)
        setURL(data.source_url)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch(err => { console.log("error", err.message); })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="col-lg-6 col-md-6">
        <div className="row">
          {ImageUrl !== "" ?
            <div className="col-3 my-2">
              <div className=" ">
                  <audio controls>
                    {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                    <source src={`${ImageUrl}`} type="audio/mpeg" />
                    <source src={`${ImageUrl}`} type="audio/mp3" />
                    <source src={`${ImageUrl}`} type="audio/wav" />
                    <source src={`${ImageUrl}`} type="audio/ogg" />
                    Your browser does not support the audio element.
                  </audio>
              </div>
              <button onClick={() => {
                setImageUrl("")
              }}
                className="bg-transparent border-0 p-1 "
              ><u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 p-1 "
              >
                <div className="border border-dark m-1 p-4 font-size-12">
                  <i className="fa fa-file-pdf-o font-size-18"></i>
                  <div>Add</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="file/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>
      <div style={{ fontSize: "8px", color: "#777" }}>
        The pdf or book uploaded should not have the copyright with some other company or party.<br></br>
        Please try not to upload pdfs that may later have compyright issues
      </div>
    </div>
  )
}