import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../../Constant/slugs';

export default function Home() {
  const dispatch = useDispatch()

  return (
    <div style={{height:"100vh",backgroundColor:"#ddd"}}>
      <div className="row">
        <div style={{ margin: "10px" }} className="center">
          <img
            src="https://api.lpkbmn.id/wp-content/uploads/2023/10/logobwm.png"
            style={{ height: "30vh", width: "30vh", objectFit: "contain" }}
          />
        </div>
        <div className="row" >
          <Link to={slugs.exam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-1.png"
            />
            <div className="home-fonts">
              UBT Exam
            </div>
          </Link>
          <Link to={slugs.exam_result_list} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-3.png"
            />
            <div className="home-fonts">
              HISTORY
            </div>
          </Link>
          <Link to={"/profile"} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-5.png"
            />
            <div className="home-fonts">
              PROFILE
            </div>
          </Link>

        </div>

        <div className="row">
          <Link to={slugs.book_list_user} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-4.png"
            />
            <div className="home-fonts">
              TEXTBOOK
            </div>
          </Link>
          <Link to={slugs.textbookexam} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-2.png"
            />
            <div className="home-fonts">
              LATIHAN MINGGUAN
            </div>
          </Link>
          <Link to={slugs.cbtexam} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpkbmn.id/wp-content/uploads/2023/10/LOGO-2.png"
            />
            <div className="home-fonts">
              UBT SPECIAL
            </div>
          </Link>

        </div>

      </div>
    </div>
  )
}