import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { headphoneURL } from '../../../Constant/api';
const AnswerOptionsSelect = ({
  setData, Data,
  DataValue, DataType,
  option,
  index, index2,
  isPlayingAnswer, setIsPlayingAnswer, setIsPlayingMedia,
  mediaPlayed, setMediaPlayed, correctAnswer, answerDescription
}) => {

  return (
    <div>
      <div className="answer-option-style"
        style={{ display: 'flex', margin: "5px" }}
      >
        <div style={{ marginRight: "5px", cursor: "pointer" }}
          onClick={() => {
            setData(option)
          }}
        >
          {Data == option ?
            <>
              {correctAnswer == option ?
                <div style={{
                  border: "4px solid #000",
                  borderRadius: "20px",
                  width: "30px",
                  height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
                  fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#1dbc5b"
                }}>
                  &nbsp;{index}&nbsp;
                </div>
                :
                <div style={{
                  border: "4px solid #000",
                  borderRadius: "20px",
                  width: "30px",
                  height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
                  fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#d5363a"
                }}>
                  &nbsp;{index}&nbsp;
                </div>
              }
            </>
            :
            <div style={{
              border: "4px solid black",
              borderRadius: "20px",
              width: "30px",
              height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
              fontSize: "16px", fontWeight: "bold"
            }}>
              &nbsp;{index}&nbsp;
            </div>
          }
        </div>

        <div className={Data == option ? "active-option2" : "passive-option2"}>
          {DataType == "text" &&
            `${DataValue}`
          }
          {DataType == "image" &&
            <img src={DataValue} style={{ width: "180px", border: "0.5px solid #000" }} />
          }
          {DataType == "audio" &&
            <div>
              <div style={{ cursor: "pointer" }} onClick={() => {
                try {
                  if (mediaPlayed[`answer-${index2}`] == "0") {
                    const sound = new Audio(DataValue)
                    setIsPlayingAnswer(true)
                    setIsPlayingMedia(true)
                    setMediaPlayed({
                      ...mediaPlayed,
                      [`answer-${index2}`]: "1"
                    })
                    sound.play()
                    sound.addEventListener('ended', () => {
                      setIsPlayingAnswer(false)
                      setIsPlayingMedia(false)
                    })
                  }
                }
                catch {
                  console.log("error")
                }
              }}>
                <div style={{ position: "absolute" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                    {isPlayingAnswer ?
                      <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                      :
                      <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                    }
                  </div>
                </div>
                <img src={headphoneURL}
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
          }
          {DataType == "video" &&
            <video width="400" controls>
              <source src={`${DataValue}`} type="video/mp4"
                style={{ height: "150px", width: "200px" }}
              />
            </video>
          }
          {DataType == "youtube" &&
            <iframe width="400" height="150" src={`https://www.youtube.com/embed/${DataValue}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          }
        </div>
      </div>
      {
        (Data == option && correctAnswer == option) &&
        <div className="question-description">
          <div style={{ fontSize: "13px", border: "1px solid #000", padding: "5px 5px" }}>
            Correct Answer is: {correctAnswer}<br />
            Description:<br />
            {answerDescription}
          </div>
        </div>

      }
        {
        (Data == option && correctAnswer !== option) &&
        <div className="question-description">
          <div style={{ fontSize: "13px", border: "1px solid #000", padding: "5px 5px" }}>
            Wrong Answer <br />
            Try Again!!<br />
          </div>
        </div>

      }
    </div >
  )
}

export default function ThridScreenComponent(props) {
  const {
    oneQuestionData,
    ArrayIndex,
    setQuestionsSolved, QuestionsSolved,
    // mediaPlayed, setMedaiPlayed,
    setIsPlayingMedia
  } = props;
  const [question, setQuestion] = React.useState(oneQuestionData.question)
  const [AnswerSelected, setAnswerSelected] = React.useState("")
  const [ImageSelect, setImageSelect] = React.useState("")

  const audioQuestionDesc = React.createRef();
  const [isPlayingQuestionMedia, setIsPlayingQuestionMedia] = React.useState(false);
  const [isPlayingAnswer1, setIsPlayingAnswer1] = React.useState(false);
  const [isPlayingAnswer2, setIsPlayingAnswer2] = React.useState(false);
  const [isPlayingAnswer3, setIsPlayingAnswer3] = React.useState(false);
  const [isPlayingAnswer4, setIsPlayingAnswer4] = React.useState(false);
  const [mediaPlayed, setMediaPlayed] = React.useState({
    "question": "0",
    "answer-a": "0",
    "answer-b": "0",
    "answer-c": "0",
    "answer-d": "0"
  })
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div style={{
            margin: "3px", padding: "3px",
            border: "1px solid #999", overflowY: 'scroll'
          }}
            className="question-height"
          >
            <div
              style={{ padding: "10px 0 0" }}
            >
              <h3 style={{ fontSize: 18, fontWeight: "bold" }}>
                {oneQuestionData.question_number}. {oneQuestionData.question}
              </h3>
            </div>

            <div className="question-description col-12">
              {oneQuestionData.question_description_type == "text" &&
                <div style={{ fontSize: "17px", border: "1px solid #000", padding: "0 0 15px" }}>
                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                </div>
              }
              {oneQuestionData.question_description_type == "image" &&
                <>
                  <div
                    onClick={() => {
                      setImageSelect(oneQuestionData.question_description)
                    }}
                  >
                    <img
                      src={oneQuestionData.question_description}
                      style={{
                        height: "300px",
                        width: "392px",
                        objectFit: 'contain',
                        border: "0.5px solid #000"
                      }}
                    />
                  </div>
                </>
              }
              {oneQuestionData.question_description_type == "audio" &&
                <div>
                  <div style={{ cursor: "pointer" }} onClick={() => {
                    try {
                      if (mediaPlayed[`question`] == "0") {
                        const sound = new Audio(oneQuestionData.question_description)
                        sound.play()
                        setIsPlayingQuestionMedia(true)
                        setIsPlayingMedia(true)
                        setMediaPlayed({
                          ...mediaPlayed,
                          "question": "1"
                        })
                        sound.addEventListener('ended', () => {
                          setIsPlayingQuestionMedia(false)
                          setIsPlayingMedia(false)
                        })
                      }
                    }
                    catch {
                      console.log("error")
                    }
                  }}>
                    <div style={{ position: "absolute" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                        {isPlayingQuestionMedia ?
                          <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                          :
                          <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                        }
                      </div>
                    </div>
                    <img src={headphoneURL}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </div>
              }
              {oneQuestionData.question_description_type == "video" &&
                <video width="400" controls>
                  <source src={`${oneQuestionData.question_description}`} type="video/mp4"
                    style={{ height: "150px", width: "200px" }}
                  />
                </video>
              }
              {oneQuestionData.question_description_type == "youtube" &&
                <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_description}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              }
            </div>

            {oneQuestionData.question_media !== "" &&
              <div className="question-description col-10">
                {oneQuestionData.question_media_type == "text" &&
                  `${oneQuestionData.question_media}`
                }

                {oneQuestionData.question_media_type == "image" &&
                  <div
                    onClick={() => {
                      setImageSelect(oneQuestionData.question_media)
                    }}
                  >
                    <img
                      src={oneQuestionData.question_media}
                      style={{
                        height: "300px",
                        width: "392px",
                        objectFit: 'contain',
                        border: "0.5px solid #000"
                      }}
                    />
                  </div>
                }
                {oneQuestionData.question_media_type == "audio" &&
                  <div style={{ cursor: "pointer" }} onClick={() => {
                    try {
                      if (mediaPlayed[`question`] == "0") {
                        const sound = new Audio(oneQuestionData.question_media)
                        sound.play()
                        setIsPlayingQuestionMedia(true)
                        setIsPlayingMedia(true)
                        setMediaPlayed({
                          ...mediaPlayed,
                          "question": "1"
                        })
                        sound.addEventListener('ended', () => {
                          setIsPlayingQuestionMedia(false)
                          setIsPlayingMedia(false)
                        })
                      }
                    }
                    catch {
                      console.log("error")
                    }
                  }}>
                    <div style={{ position: "absolute" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                        {isPlayingQuestionMedia ?
                          <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                          :
                          <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                        }
                      </div>
                    </div>
                    <img src={headphoneURL}
                      style={{ width: "100px", height: "100px" }}
                    />

                  </div>
                }
                {oneQuestionData.question_media_type == "video" &&
                  <video width="400" controls>
                    <source src={`${oneQuestionData.question_media}`} type="video/mp4"
                      style={{ height: "150px", width: "200px" }}
                    />
                  </video>
                }
                {oneQuestionData.question_media_type == "youtube" &&
                  <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_media}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
            }
          </div>
        </div>
        <div className="col-md-4">
          <div style={{ margin: "0 3px", padding: "0 10px", border: "1px solid #999", overflowY: 'scroll' }}
            className="question-height"
          >
            <AnswerOptionsSelect
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_1}
              DataType={oneQuestionData.answer_media_type}
              option={"option 1"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={1}
              index2={"a"}
              isPlayingAnswer={isPlayingAnswer1}
              setIsPlayingAnswer={setIsPlayingAnswer1}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMediaPlayed}
              correctAnswer={oneQuestionData.correct_answer}
              answerDescription={oneQuestionData.answer_description}
            />
            <AnswerOptionsSelect
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_2}
              DataType={oneQuestionData.answer_media_type}
              option={"option 2"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={2}
              index2={"b"}
              isPlayingAnswer={isPlayingAnswer2}
              setIsPlayingAnswer={setIsPlayingAnswer2}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMediaPlayed}
              correctAnswer={oneQuestionData.correct_answer}
              answerDescription={oneQuestionData.answer_description}
            />
            <AnswerOptionsSelect
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_3}
              DataType={oneQuestionData.answer_media_type}
              option={"option 3"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={3}
              index2={"c"}
              isPlayingAnswer={isPlayingAnswer3}
              setIsPlayingAnswer={setIsPlayingAnswer3}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMediaPlayed}
              correctAnswer={oneQuestionData.correct_answer}
              answerDescription={oneQuestionData.answer_description}
            />
            <AnswerOptionsSelect
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_4}
              DataType={oneQuestionData.answer_media_type}
              option={"option 4"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={4}
              index2={"d"}
              isPlayingAnswer={isPlayingAnswer4}
              setIsPlayingAnswer={setIsPlayingAnswer4}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMediaPlayed}
              correctAnswer={oneQuestionData.correct_answer}
              answerDescription={oneQuestionData.answer_description}
            />
          </div>
        </div>
      </div>
      {
        ImageSelect !== "" &&
        <div className="modal center"
          onClick={() => {
            setImageSelect("")
          }}
        >
          <img className="image-modal"
            src={ImageSelect}
          />

        </div>
      }
    </div >
  )
}