import React from "react";
import FormComponent from "./Components/Form";
import Database from './../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
export default function NewBook() {
  const FetchData = Database.books;
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Book</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_books}>All Books</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
      />
    </div>
  )
}