import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.books_details}${row.slug}`}>View</Link>,

  }
];

export default function AllBooks() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    // setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_book)
      .then((res) => {
        // setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 1000,
      _fields: "id,title,slug,meta,status"
    })

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Books</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Books
        </div>
      </div>
      <div style={{ display: "inline" }}>
        <div style={{ display: "inline-block" }}>
          <a href={slugs.new_book}>
            <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
              onClick={() => {

              }}
            >Add New Book</div>
          </a>
        </div>
        <div style={{ display: "inline-block" }}>
          {Projects1.filter(a => a.status == "publish").length !== 0 &&
            <span style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setToStudentModal(true)

              }
              }
            >
              Delete Books |
            </span>
          }
        </div>
      </div>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            setProjects1(r.selectedRows)
          }}
        />
      </div>
      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                  }}
                >X</div>
                <div>
                  <center>
                    Do you really want to Delete all the books?<br />
                    This Process is irreversible
                    <div className="row">
                      <div className="col-3 center " />
                      <div className="col-3 center " >
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setToStudentModal(false)
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                      <div className="col-3 center">
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setToStudentModal(false)
                            for (let i = 0; i < Projects1.length; i++) {
                              setReload(true)
                              AdsPUTApi({
                                status: "draft"
                              }, slugs.db_slug_book, Projects1[i].id)
                                .then(res => {
                                  if (i == Projects1.length - 1) {
                                    setProjects1([])
                                    GetAllProjects({
                                      per_page: 100,
                                      _fields: "id,title,slug,meta"
                                    })
                                  }
                                })
                            }
                          }}
                        >
                          Delete All Books
                        </div>
                      </div>
                    </div>
                  </center>
                </div>

                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects1}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}